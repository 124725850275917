import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import TraficView from './sl/TraficView';
import WeatherView from './weather/WeatherView'
import AlicanView from './alican/AlicanView';
import DocumentView from './document/DocumentView';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<AlicanView />} />
          <Route path="weather" element={<WeatherView />} />
          <Route path="sl" element={<TraficView />} />
          <Route path="cv" element={<DocumentView />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;