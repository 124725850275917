import React, { useEffect, useState } from 'react';
import { plainToInstance } from 'class-transformer';
import { Weather, WeatherCondition, WindDirection } from './Weather';

const getWeather = async (city: string): Promise<Weather> => {
    let url: string = "";

    if(city === 'handen'){
        url = "https://api.open-meteo.com/v1/forecast?latitude=59.16&longitude=18.13&daily=sunrise,sunset,uv_index_max&current_weather=true&windspeed_unit=ms&forecast_days=1&timezone=Europe%2FBerlin";
    }
    else if(city === 'stockholm'){
        url = "https://api.open-meteo.com/v1/forecast?latitude=59.33&longitude=18.07&daily=sunrise,sunset,uv_index_max&current_weather=true&windspeed_unit=ms&forecast_days=1&timezone=Europe%2FBerlin";
    }
    else if(city === 'alanya'){
        url = "https://api.open-meteo.com/v1/forecast?latitude=36.49&longitude=32.09&daily=sunrise,sunset,uv_index_max&current_weather=true&windspeed_unit=ms&forecast_days=1&timezone=Europe%2FBerlin";
    }

    let response = await fetch(url);
    let json = await response.json() as Weather;
    let weather = plainToInstance(Weather, json);

    return weather;
}

const WeatherView = () => {
    const [handenWeather, handenWeatherSet] = useState<Weather>();
    const [sthlmWeather, sthlmWeatherSet] = useState<Weather>();
    const [alanyaWeather, alanyaWeatherSet] = useState<Weather>();

    useEffect(() => {
        getWeather('handen').then(weather => handenWeatherSet(weather));
    }, []);

    useEffect(() => {
        getWeather('stockholm').then(weather => sthlmWeatherSet(weather));
    }, []);

    useEffect(() => {
        getWeather('alanya').then(weather => alanyaWeatherSet(weather));
    }, []);

    return(
        <div className="weather-container">
            <div className="weather-card">
                <h2 className="weather-city">HANDEN</h2>
                <div className="weather-info">
                    <p className="weather-detail">
                        <span className="weather-detail-title">Time:</span>
                        <span className="weather-detail-value">{handenWeather?.current_weather.time}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Temperature:</span>
                        <span className="weather-detail-value">{handenWeather?.current_weather.temperature}°C</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Wind Speed:</span>
                        <span className="weather-detail-value">{handenWeather?.current_weather.windspeed}m/s</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Wind Direction:</span>
                        <span className="weather-detail-value">{WindDirection.getWindDirection(handenWeather?.current_weather.winddirection)}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Weather Code:</span>
                        <span className="weather-detail-value">{WeatherCondition.getWeatherCondition(handenWeather?.current_weather.weathercode)}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Daytime:</span>
                        <span className="weather-detail-value">{handenWeather?.current_weather.is_day ? 'Yes' : 'No'}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Sunrise:</span>
                        <span className="weather-detail-value">{handenWeather?.daily.sunrise[0]}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Sunset:</span>
                        <span className="weather-detail-value">{handenWeather?.daily.sunset[0]}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">UV Index:</span>
                        <span className="weather-detail-value">{handenWeather?.daily.uv_index_max[0]}</span>
                    </p>
                </div>
            </div>
            <div className="weather-card">
                <h2 className="weather-city">STOCKHOLM</h2>
                <div className="weather-info">
                    <p className="weather-detail">
                        <span className="weather-detail-title">Time:</span>
                        <span className="weather-detail-value">{sthlmWeather?.current_weather.time}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Temperature:</span>
                        <span className="weather-detail-value">{sthlmWeather?.current_weather.temperature}°C</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Wind Speed:</span>
                        <span className="weather-detail-value">{sthlmWeather?.current_weather.windspeed}m/s</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Wind Direction:</span>
                        <span className="weather-detail-value">{WindDirection.getWindDirection(sthlmWeather?.current_weather.winddirection)}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Weather Code:</span>
                        <span className="weather-detail-value">{WeatherCondition.getWeatherCondition(sthlmWeather?.current_weather.weathercode)}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Daytime:</span>
                        <span className="weather-detail-value">{sthlmWeather?.current_weather.is_day ? 'Yes' : 'No'}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Sunrise:</span>
                        <span className="weather-detail-value">{sthlmWeather?.daily.sunrise[0]}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Sunset:</span>
                        <span className="weather-detail-value">{sthlmWeather?.daily.sunset[0]}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">UV Index:</span>
                        <span className="weather-detail-value">{sthlmWeather?.daily.uv_index_max[0]}</span>
                    </p>
                </div>
            </div>
            <div className="weather-card">
                <h2 className="weather-city">ALANYA</h2>
                <div className="weather-info">
                    <p className="weather-detail">
                        <span className="weather-detail-title">Time:</span>
                        <span className="weather-detail-value">{alanyaWeather?.current_weather.time}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Temperature:</span>
                        <span className="weather-detail-value">{alanyaWeather?.current_weather.temperature}°C</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Wind Speed:</span>
                        <span className="weather-detail-value">{alanyaWeather?.current_weather.windspeed}m/s</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Wind Direction:</span>
                        <span className="weather-detail-value">{WindDirection.getWindDirection(alanyaWeather?.current_weather.winddirection)}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Weather Code:</span>
                        <span className="weather-detail-value">{WeatherCondition.getWeatherCondition(alanyaWeather?.current_weather.weathercode)}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Daytime:</span>
                        <span className="weather-detail-value">{alanyaWeather?.current_weather.is_day ? 'Yes' : 'No'}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Sunrise:</span>
                        <span className="weather-detail-value">{alanyaWeather?.daily.sunrise[0]}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">Sunset:</span>
                        <span className="weather-detail-value">{alanyaWeather?.daily.sunset[0]}</span>
                    </p>
                    <p className="weather-detail">
                        <span className="weather-detail-title">UV Index:</span>
                        <span className="weather-detail-value">{alanyaWeather?.daily.uv_index_max[0]}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default WeatherView;