import React, { useEffect, useState } from 'react';

const getHttpStatus = async (): Promise<number> => {
    let response: Response = await fetch('https://api.sl.se/api2/realtimedeparturesV4.json');
    let code = response.status;
    return code;
}

const HttpStatusView = () => {
    const [status, statusSet] = useState<number>();

    useEffect(() => {
        getHttpStatus().then(x => statusSet(x));
    }, []);

    if(status === 200){
        return(
            <div className="indicator online">
                <span className="http-status">Service is <strong>online</strong></span>
                <code className="http-ok-code">HTTP code: {status}</code>
            </div>
        )
    }
    else{
        return(
            <div className="indicator offline">
                <span className="http-status">Service is <strong>offline</strong></span>
                <code className="http-error-code">HTTP code: {status}</code>
            </div>
        )
    }
}

export default HttpStatusView;