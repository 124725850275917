import React from 'react';

const DocumentView = () => {
    return(
        <div>
            <iframe src="/assets/document/AlicanBircanCV.pdf" title="PDF Viewer" width="100%" height="100%">
                This browser does not support PDFs. Please download the PDF to view it.
            </iframe>
        </div>
    )
}

export default DocumentView;