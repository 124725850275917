import React, { useEffect, useState } from 'react';
import { plainToInstance } from 'class-transformer';
import { Departures } from './Departures';
import HttpStatusView from './HttpStatusView';

const getDepartures = async (siteId: number): Promise<Departures> => {
    let response = await fetch('https://sl-api-container.victorioussmoke-9c622005.swedencentral.azurecontainerapps.io/trafic?siteId=' + siteId);
    let json = await response.json() as Departures;
    let departures = plainToInstance(Departures, json);

    return departures;
}

const formatDate = (datetime: string) : string => {
    try{
        const date = new Date(datetime);
        const time = date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        return time;
    }
    catch(error){
        return "-";
    }
}

const handenSiteId = 9730;
const sthlmSiteId = 1080;
const radhusetSiteId = 9309;

const TraficView = () => {
    const [handenDepartures, handenDeparturesSet] = useState<Departures>();
    const [sthlmDepartures, sthlmDeparturesSet] = useState<Departures>();
    const [radhusetDepartures, radhusetDeparturesSet] = useState<Departures>();

    useEffect(() => {
        getDepartures(handenSiteId).then(departures => handenDeparturesSet(departures));
    }, []);

    useEffect(() => {
        getDepartures(sthlmSiteId).then(departures => sthlmDeparturesSet(departures));
    }, []);

    useEffect(() => {
        getDepartures(radhusetSiteId).then(departures => radhusetDeparturesSet(departures));
    }, []);

    return(
        <div>
            <h2>Avångar från <b>HANDEN</b></h2>
            <table>
                <thead>
                    <tr>
                        <th>Linje</th>
                        <th>Destination</th>
                        <th>Tabelltid</th>
                        <th>Avgångstid</th>
                        <th>Plattform</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {handenDepartures?.responseData.trains.map((departure, index) => (
                        <tr key={index.toString()}>
                            <td>{departure.lineNumber}</td>
                            <td>{departure.destination}</td>
                            <td>{formatDate(departure.timeTabledDateTime)}</td>
                            <td>{departure.displayTime}</td>
                            <td>{departure.stopPointDesignation}</td>
                            <td>{StatusView(departure.timeTabledDateTime, departure.expectedDateTime)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <h2>Avångar från <b>STOCKHOLM CITY</b></h2>
            <table>
                <thead>
                    <tr>
                        <th>Linje</th>
                        <th>Destination</th>
                        <th>Tabelltid</th>
                        <th>Avgångstid</th>
                        <th>Plattform</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {sthlmDepartures?.responseData.trains.map((departure, index) => (
                        <tr key={index.toString()}>
                            <td>{departure.lineNumber}</td>
                            <td>{departure.destination}</td>
                            <td>{formatDate(departure.timeTabledDateTime)}</td>
                            <td>{departure.displayTime}</td>
                            <td>{departure.stopPointDesignation}</td>
                            <td>{StatusView(departure.timeTabledDateTime, departure.expectedDateTime)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <h2>Avångar från <b>RÅDHUSET</b></h2>
            <table>
                <thead>
                    <tr>
                        <th>Linje</th>
                        <th>Destination</th>
                        <th>Tabelltid</th>
                        <th>Avgångstid</th>
                        <th>Plattform</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {radhusetDepartures?.responseData.metros.map((departure, index) => (
                        <tr key={index.toString()}>
                            <td>{departure.lineNumber}</td>
                            <td>{departure.destination}</td>
                            <td>{formatDate(departure.timeTabledDateTime)}</td>
                            <td>{departure.displayTime}</td>
                            <td>{departure.stopPointDesignation}</td>
                            <td>{StatusView(departure.timeTabledDateTime, departure.expectedDateTime)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <HttpStatusView />
        </div>
    ) 
}

const StatusView = (tabelltid: string, avgangstid: string) => {
    tabelltid = formatDate(tabelltid);
    avgangstid = formatDate(avgangstid);

    if(tabelltid !== avgangstid)
        return (<span className="status delayed">Delayed {avgangstid}</span>);
    else
        return (<span className="status on-time">On Time</span>);
}

export default TraficView;