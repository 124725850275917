export class Departures {
    statusCode: number;
    message: any;
    executionTime: number;
    responseData: ResponseData;

    constructor(statusCode: number, message: any, executionTime: number, responseData: ResponseData) {
        this.statusCode = statusCode;
        this.message = message; 
        this.executionTime = executionTime;
        this.responseData = responseData;
    }
}
  
export class ResponseData {
    latestUpdate: string;
    dataAge: number;
    metros: Metro[];
    buses: Bus[];
    trains: Train[];
    trams: any[];
    ships: any[];
    stopPointDeviations: any[];

    constructor(latestUpdate: string, dataAge: number, metros: Metro[], buses: Bus[], trains: Train[], 
        trams: any[], ships: any[], stopPointDeviations: any[]) {
        this.latestUpdate = latestUpdate;
        this.dataAge = dataAge;
        this.metros = metros;
        this.buses = buses;
        this.trains = trains;
        this.trams = trams;
        this.ships = ships;
        this.stopPointDeviations = stopPointDeviations;
    }
}
  
export class Bus {
    groupOfLine: any;
    transportMode: string;
    lineNumber: string;
    destination: string;
    journeyDirection: number;
    stopAreaName: string;
    stopAreaNumber: number;
    stopPointNumber: number;
    stopPointDesignation: string;
    timeTabledDateTime: string;
    expectedDateTime: string;
    displayTime: string;
    journeyNumber: number;
    deviations: Deviation[];

    constructor(groupOfLine: any, transportMode: string, lineNumber: string, destination: string, journeyDirection: number,
        stopAreaName: string, stopAreaNumber: number, stopPointNumber: number, stopPointDesignation: string, 
        timeTabledDateTime: string, expectedDateTime: string, displayTime: string, journeyNumber: number, deviations: Deviation[]) {
        this.groupOfLine = groupOfLine;
        this.transportMode = transportMode;
        this.lineNumber = lineNumber;
        this.destination = destination;
        this.journeyDirection = journeyDirection;
        this.stopAreaName = stopAreaName;
        this.stopAreaNumber = stopAreaNumber;
        this.stopPointNumber = stopPointNumber;
        this.stopPointDesignation = stopPointDesignation;
        this.timeTabledDateTime = timeTabledDateTime;
        this.expectedDateTime = expectedDateTime;
        this.displayTime = displayTime;
        this.journeyNumber = journeyNumber;
        this.deviations = deviations;
    }
}
  
export class Train {
    secondaryDestinationName: any;
    groupOfLine: string;
    transportMode: string;
    lineNumber: string;
    destination: string;
    journeyDirection: number;
    stopAreaName: string;
    stopAreaNumber: number;
    stopPointNumber: number;
    stopPointDesignation: string;
    timeTabledDateTime: string;
    expectedDateTime: string;
    displayTime: string;
    journeyNumber: number;
    deviations: Deviation[];

    constructor(secondaryDestinationName: any, groupOfLine: any, transportMode: string, lineNumber: string, destination: string, journeyDirection: number,
        stopAreaName: string, stopAreaNumber: number, stopPointNumber: number, stopPointDesignation: string, 
        timeTabledDateTime: string, expectedDateTime: string, displayTime: string, journeyNumber: number, deviations: Deviation[]) {
        this.secondaryDestinationName = secondaryDestinationName;
        this.groupOfLine = groupOfLine;
        this.transportMode = transportMode;
        this.lineNumber = lineNumber;
        this.destination = destination;
        this.journeyDirection = journeyDirection;
        this.stopAreaName = stopAreaName;
        this.stopAreaNumber = stopAreaNumber;
        this.stopPointNumber = stopPointNumber;
        this.stopPointDesignation = stopPointDesignation;
        this.timeTabledDateTime = timeTabledDateTime;
        this.expectedDateTime = expectedDateTime;
        this.displayTime = displayTime;
        this.journeyNumber = journeyNumber;
        this.deviations = deviations;
    }
}

export class Metro {
    groupOfLine: any;
    transportMode: string;
    lineNumber: string;
    destination: string;
    journeyDirection: number;
    stopAreaName: string;
    stopAreaNumber: number;
    stopPointNumber: number;
    stopPointDesignation: string;
    timeTabledDateTime: string;
    expectedDateTime: string;
    displayTime: string;
    journeyNumber: number;
    deviations: Deviation[];

    constructor(groupOfLine: any, transportMode: string, lineNumber: string, destination: string, journeyDirection: number,
        stopAreaName: string, stopAreaNumber: number, stopPointNumber: number, stopPointDesignation: string, 
        timeTabledDateTime: string, expectedDateTime: string, displayTime: string, journeyNumber: number, deviations: Deviation[]) {
        this.groupOfLine = groupOfLine;
        this.transportMode = transportMode;
        this.lineNumber = lineNumber;
        this.destination = destination;
        this.journeyDirection = journeyDirection;
        this.stopAreaName = stopAreaName;
        this.stopAreaNumber = stopAreaNumber;
        this.stopPointNumber = stopPointNumber;
        this.stopPointDesignation = stopPointDesignation;
        this.timeTabledDateTime = timeTabledDateTime;
        this.expectedDateTime = expectedDateTime;
        this.displayTime = displayTime;
        this.journeyNumber = journeyNumber;
        this.deviations = deviations;
    }
}
  
export class Deviation {
    text: string;
    consequence: string;
    importanceLevel: number;

    constructor(text: string, consequences: string, importanceLevel: number) {
        this.text = text;
        this.consequence = consequences;
        this.importanceLevel = importanceLevel;
    }
}