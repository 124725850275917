export class Weather {
    latitude: number;
    longitude: number;
    generationtime_ms: number;
    utc_offset_seconds: number;
    timezone: string;
    timezone_abbreviation: string;
    elevation: number;
    current_weather: CurrentWeather;
    hourly_units: HourlyUnits;
    hourly: Hourly;
    daily_units: DailyUnits;
    daily: Daily;

    constructor(latitude: number, longitude: number, generationtime_ms: number, utc_offset_seconds: number, 
        timezone: string, timezone_abbreviation: string, elevation: number, current_weather: CurrentWeather, 
        hourly_units: HourlyUnits, hourly: Hourly, daily_units: DailyUnits, daily: Daily) {
        this.latitude = latitude;
        this.longitude = longitude;
        this.generationtime_ms = generationtime_ms;
        this.utc_offset_seconds = utc_offset_seconds;
        this.timezone = timezone;
        this.timezone_abbreviation = timezone_abbreviation;
        this.elevation = elevation;
        this.hourly_units = hourly_units;
        this.hourly = hourly;
        this.daily_units = daily_units;
        this.daily = daily;
        this.current_weather = current_weather;
    }
}

export class CurrentWeather {
    temperature: number;
    windspeed: number;
    winddirection: number;
    weathercode: number;
    is_day: boolean;
    time: string;

    constructor(temperature: number, windspeed: number, winddirection: number, weathercode: number, is_day: boolean, time: string) {
        this.temperature = temperature;
        this.windspeed = windspeed;
        this.winddirection = winddirection;
        this.weathercode = weathercode;
        this.is_day = is_day;
        this.time = time;
    }
}

export class HourlyUnits {
    time: string;
    temperature_2m: string;
    relativehumidity_2m: string;
    precipitation: string;

    constructor(time: string, temperature_2m: string, relativehumidity_2m: string, precipitation: string) {
        this.time = time;
        this.temperature_2m = temperature_2m;
        this.relativehumidity_2m = relativehumidity_2m;
        this.precipitation = precipitation;
    }
}

export class Hourly {
    time: string[];
    temperature_2m: number[];
    relativehumidity_2m: number[];
    precipitation: any[];

    constructor(time: string[], temperature_2m: number[], relativehumidity_2m: number[], precipitation: any[]) {
        this.time = time;
        this.temperature_2m = temperature_2m;
        this.relativehumidity_2m = relativehumidity_2m;
        this.precipitation = precipitation;
    }
}

export class DailyUnits {
    time: string;
    sunrise: string;
    sunset: string;
    uv_index_max: any;

    constructor(time: string, sunrise: string, sunset: string, uv_index_max: any){
        this.time = time;
        this.sunrise = sunrise;
        this.sunset = sunset;
        this.uv_index_max = uv_index_max;
    }
}

export class Daily {
    time: string[];
    sunrise: string[];
    sunset: string[];
    uv_index_max: any[];

    constructor(time: string[], sunrise: string[], sunset: string[], uv_index_max: any[]){
        this.time = time;
        this.sunrise = sunrise;
        this.sunset = sunset;
        this.uv_index_max = uv_index_max;
    }
}

export class WeatherCondition {
    static getWeatherCondition(weatherCode: number | undefined): string {
        switch (weatherCode) {
          case 0:
            return "Clear sky or sunny weather";
          case 1:
            return "Cloudy weather";
          case 2:
            return "Partly cloudy weather";
          case 3:
            return "Mist or foggy conditions";
          case 4:
            return "Drizzle or light rain";
          case 5:
            return "Rain";
          case 6:
            return "Snow";
          case 7:
            return "Thunderstorms";
          case 8:
            return "Freezing rain or sleet";
          case 9:
            return "Hail";
          default:
            return "Unknown weather condition";
        }
    }
}

export class WindDirection {
    static getWindDirection(windCode: number | undefined): string {
      const directions = [
        "N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE",
        "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"
      ];
  
      if(windCode === undefined){
        return "NaN";
      }

      const index = Math.round(windCode / 22.5) % 16;
      return directions[index];
    }
}